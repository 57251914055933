import { Upload as BaseUpload, UploadFile, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useCallback } from 'react';
import { File as ApiFile } from '../../../graphql/schema';

export interface UploadProps {
  value?: (RcFile | ApiFile)[] | RcFile | ApiFile | undefined;
  onChange?: (val: (RcFile | ApiFile)[] | RcFile | ApiFile | undefined) => void;
  maxCount?: number;
  allowedMimeTypes?: string[];
  children: any;
}

const Upload = ({
  value,
  onChange,
  maxCount,
  allowedMimeTypes,
  children,
}: UploadProps) => {
  const onBeforeUpload = useCallback(
    (file: RcFile) => {
      if (allowedMimeTypes && !allowedMimeTypes.includes(file.type)) {
        message.error('Dieses Dateiformat wird leider nicht unterstützt!');
        return false;
      }

      if (file.size / 1024 / 1024 > 20) {
        message.error('Dateien dürfen maximal 20MB groß sein!');
        return false;
      }

      return true;
    },
    [allowedMimeTypes],
  );

  const onUpload = useCallback(
    async ({
      onSuccess,
    }: {
      onSuccess?: (body?: any) => void;
    }) => {
      if (onSuccess) onSuccess();
    },
    [],
  );

  const handleChange = useCallback(
    ({ fileList }: { fileList: UploadFile[] }) => {
      if (onChange) {
        if (maxCount && maxCount > 1) {
          const oldFiles = Array.isArray(value) ? value : value ? [value] : [];
          onChange([
            ...oldFiles,
            ...fileList
              .map((file) => file.originFileObj)
              .filter((file) => file !== undefined),
          ]);
        } else {
          if (fileList[0].originFileObj) onChange(fileList[0].originFileObj);
        }
      }
    },
    [onChange, maxCount, value],
  );

  return (
    <BaseUpload
      beforeUpload={onBeforeUpload}
      customRequest={onUpload}
      onChange={handleChange}
      maxCount={maxCount ?? 1}
      multiple={!!(maxCount && maxCount > 1)}
      showUploadList={false}
      className="max-w-[400px] block"
      fileList={[]}
      accept={allowedMimeTypes?.join(',')}
    >
      {children}
    </BaseUpload>
  );
};

export default Upload;
