import { User } from 'oidc-client-ts';
import { COGNITO_AUTHORITY, COGNITO_CLIENT_ID } from '../../constants';

const getToken = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${COGNITO_AUTHORITY}:${COGNITO_CLIENT_ID}`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  return user?.access_token;
};

export default getToken;
