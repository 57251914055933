const deepRemoveObjectProperty = <T = any>(
  propertyName: string,
  data: T,
): T => {
  if (!data) return data;
  if (Array.isArray(data)) {
    return data.map((item: any) =>
      deepRemoveObjectProperty(propertyName, item),
    ) as T;
  }
  if (typeof data === 'object') {
    const newObject: any = {};
    for (const key in data) {
      if (key !== propertyName) {
        newObject[key] = deepRemoveObjectProperty(propertyName, data[key]);
      }
    }
    return newObject as T;
  }
  return data;
};

export default deepRemoveObjectProperty;
