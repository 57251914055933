import { ApolloCache, gql } from '@apollo/client';
import { faPencil, faStar, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { faStar as faFilledStar } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input } from 'antd';
import React, { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ListTaskFragment,
  useAddFavoriteMutation,
  useDeleteTaskMutation,
  useRemoveFavoriteMutation,
  useUpdateTaskMutation,
} from '../../graphql/schema';
import useFormModal from '../../helper/useFormModal';
import ContextMenu from '../base/ContextMenu';

interface Props {
  task: ListTaskFragment;
  activeTaskId?: string;
}

const TasksSidebarEntry = ({ task, activeTaskId }: Props) => {
  const navigate = useNavigate();

  const [deleteTask] = useDeleteTaskMutation({
    variables: {
      id: task.id,
    },
    refetchQueries: ['GetTasks'],
  });

  const [addFavorite, { loading: addFavoriteLoading }] =
    useAddFavoriteMutation();
  const [removeFavorite, { loading: removeFavoriteLoading }] =
    useRemoveFavoriteMutation();

  const [updateTask] = useUpdateTaskMutation();

  const [updateTaskSummaryModal, openUpdateTaskSummaryModal] = useFormModal<{
    summary: string;
  }>({
    title: 'Aufgabe umbenennen',
    okText: 'Speichern',
    cancelText: 'Abbrechen',
    renderForm: (form, formProps) => (
      <Form form={form} layout="vertical" {...formProps}>
        <Form.Item name="summary" label="Beschreibung" required>
          <Input />
        </Form.Item>
      </Form>
    ),
  });

  const handleDeleteTask = useCallback(async () => {
    await deleteTask();
    navigate('/tasks', {
      replace: true,
    });
  }, [deleteTask, navigate]);

  const handleRenameTask = useCallback(async () => {
    const fields = await openUpdateTaskSummaryModal({
      summary: task.summary ?? '',
    });
    if (fields && fields.summary.trim().length > 0) {
      await updateTask({
        variables: {
          id: task.id,
          dto: {
            summary: fields.summary,
          },
        },
      });
    }
  }, [updateTask, task, openUpdateTaskSummaryModal]);

  const handleToggleFavorite = useCallback(async () => {
    const update = (cache: ApolloCache<any>) => {
      cache.writeFragment({
        id: `${task.__typename}:${task.id}`,
        fragment: gql`
                      fragment MyTask on Task {
                          isFavorite
                      }
                  `,
        data: {
          isFavorite: !task.isFavorite,
        },
      });
    };

    if (task.isFavorite) {
      await removeFavorite({
        variables: {
          dto: {
            taskId: task.id,
          },
        },
        update,
      });
    } else {
      await addFavorite({
        variables: {
          dto: {
            taskId: task.id,
          },
        },
        update,
      });
    }
  }, [task, addFavorite, removeFavorite]);

  return (
    <div
      onClick={() => navigate(`/task/${task.id}`)}
      className={`theme-bg-primary-hover ${activeTaskId === task.id ? 'theme-bg-primary' : ''} w-full cursor-pointer rounded-xl p-[5px] px-[10px] block text-sm relative group`}
      title={task.summary ?? task.id}
    >
      <div className="text-xs opacity-60 space-x-1">
        {task.isFavorite && <FontAwesomeIcon icon={faFilledStar} />}
        <span>{task.taskDefinitionLabel ?? 'Chat'}</span>
      </div>
      <div className="text-ellipsis text-nowrap overflow-hidden">
        {task.summary ?? task.id}
      </div>

      <ContextMenu
        entries={[
          {
            label: 'Umbenennen',
            key: 'rename',
            onClick: handleRenameTask,
            icon: <FontAwesomeIcon icon={faPencil} />,
          },
          {
            label: 'Löschen',
            key: 'delete',
            onClick: handleDeleteTask,
            confirmText: 'Sicher?',
            icon: <FontAwesomeIcon icon={faTrashCan} />,
          },
          {
            label: task.isFavorite
              ? 'Aus Favoriten entfernen'
              : 'Als Favorit markieren',
            key: 'favorite',
            onClick: handleToggleFavorite,
            icon: <FontAwesomeIcon icon={faStar} />,
          },
        ]}
      >
        <div className="lg:absolute right-0 top-0 h-full w-[25px] theme-bg-primary justify-center items-center rounded-tr-xl rounded-br-xl hidden lg:group-hover:flex">
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </ContextMenu>

      {updateTaskSummaryModal}
    </div>
  );
};

export default TasksSidebarEntry;
