import { ApolloCache, gql } from '@apollo/client';
import { faCog, faSpinner, faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarFilled } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Role,
  TaskDefinition,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} from '../../graphql/schema';
import useUser from '../../helper/useUser';
import Tile from './Tile';

interface Props {
  taskDefinition: TaskDefinition;
}

const TaskDefinitionTile = ({ taskDefinition }: Props) => {
  const navigate = useNavigate();
  const { hasRole } = useUser();

  const [addFavorite, { loading: addFavoriteLoading }] =
    useAddFavoriteMutation();
  const [removeFavorite, { loading: removeFavoriteLoading }] =
    useRemoveFavoriteMutation();

  const handleOpenSettings = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      navigate(`/taskDefinitionSettings/${taskDefinition.id}`);
    },
    [taskDefinition, navigate],
  );

  const handleToggleFavorite = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();

      const update = (cache: ApolloCache<any>) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'favorites',
        });
        cache.writeFragment({
          id: `${taskDefinition.__typename}:${taskDefinition.id}`,
          fragment: gql`
                fragment MyTaskDefinition on TaskDefinition {
                    isFavorite
                }
            `,
          data: {
            isFavorite: !taskDefinition.isFavorite,
          },
        });
      };

      if (taskDefinition.isFavorite) {
        await removeFavorite({
          variables: {
            dto: {
              taskDefinitionId: taskDefinition.id,
            },
          },
          update,
        });
      } else {
        await addFavorite({
          variables: {
            dto: {
              taskDefinitionId: taskDefinition.id,
            },
          },
          update,
        });
      }
    },
    [taskDefinition, addFavorite, removeFavorite],
  );

  return (
    <Tile
      link={`/createTask/${taskDefinition.id}`}
      title={taskDefinition.title}
      description={taskDefinition.description}
      illustration={taskDefinition.illustration}
    >
      <div className="absolute top-[15px] right-[15px] space-x-5">
        {hasRole([Role.SYSTEM_ADMIN, Role.COMPANY_ADMIN]) &&
          taskDefinition.settingsFormDefinition &&
          taskDefinition.settingsFormDefinition.length > 0 && (
            <div className="cursor-pointer p-2" onClick={handleOpenSettings}>
              <FontAwesomeIcon icon={faCog} />
            </div>
          )}

        {addFavoriteLoading || removeFavoriteLoading ? (
          <div className="cursor-pointer p-2">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className="cursor-pointer p-2" onClick={handleToggleFavorite}>
            <FontAwesomeIcon
              icon={taskDefinition.isFavorite ? faStarFilled : faStar}
            />
          </div>
        )}
      </div>
    </Tile>
  );
};

export default TaskDefinitionTile;
