import {
  IconDefinition,
  faCogs,
  faComment,
  faDoorOpen,
  faHouse,
  faKey,
  faMegaphone,
  faSearch,
  faStar,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { useCallback, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { COGNITO_CLIENT_ID } from '../../constants';
import { Role } from '../../graphql/schema';
import useIsMobile from '../../helper/useIsMobile';
import useUser from '../../helper/useUser';
import { sentryFeedbackIntegration } from '../../sentry';

interface MenuItem {
  key: string;
  label?: string;
  icon?: IconDefinition;
  onClick?: () => void;
}

const useMenuItems = () => {
  const { hasRole } = useUser();
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const { signoutRedirect } = useAuth();

  const handleLogout = useCallback(() => {
    void signoutRedirect({
      extraQueryParams: {
        client_id: COGNITO_CLIENT_ID,
        logout_uri: `${window.location.protocol}//${window.location.host}/logout`,
      },
    });
  }, [signoutRedirect]);

  return useMemo(() => {
    const items: MenuItem[] = [];

    if (isMobile) {
      items.push(
        ...[
          {
            key: 'home',
            label: 'Übersicht',
            icon: faHouse,
            onClick: () => navigate('/'),
          },
          {
            key: 'tasks',
            label: 'Meine Aktivitäten',
            icon: faComment,
            onClick: () => navigate('/tasks'),
          },
          {
            key: 'favorites',
            label: 'Favoriten',
            icon: faStar,
            onClick: () => navigate('/favorites'),
          },
          {
            key: 'search',
            label: 'Suche',
            icon: faSearch,
            onClick: () => navigate('/search'),
          },
          {
            key: 'dividerMain',
          },

          {
            key: 'feedback',
            label: 'Feedback',
            icon: faMegaphone,
            onClick: async () => {
              if (sentryFeedbackIntegration) {
                const form = await sentryFeedbackIntegration.createForm();

                form.appendToDom();
                form.open();
              }
            },
          },
          {
            key: 'dividerFeedback',
          },
        ],
      );
    }

    if (hasRole([Role.SYSTEM_ADMIN])) {
      items.push({
        key: 'system',
        label: 'System',
        icon: faWrench,
        onClick: () => navigate('/admin'),
      });
    }

    if (hasRole([Role.COMPANY_ADMIN, Role.SYSTEM_ADMIN])) {
      items.push({
        key: 'settings',
        label: 'Einstellungen',
        icon: faCogs,
        onClick: () => navigate('/companyAdmin'),
      });
      items.push({
        key: 'dividerSettings',
      });
    }

    items.push({
      key: 'changePassword',
      icon: faKey,
      label: 'Passwort ändern',
      onClick: () => navigate('/changePassword'),
    });

    items.push({
      key: 'logout',
      icon: faDoorOpen,
      label: 'Abmelden',
      onClick: handleLogout,
    });

    return items;
  }, [handleLogout, navigate, hasRole, isMobile]);
};

export default useMenuItems;
