import Loading from '../base/Loading';
import MobileDrawer from '../layout/MobileDrawer';

interface Props {
  children: any;
  sidebar?: any;
  loading?: boolean;
}

const TilesGrid = ({ children, sidebar, loading }: Props) => {
  if (sidebar) {
    return (
      <div className="flex flex-row lg:space-x-6">
        <MobileDrawer>
          <div className="lg:w-[300px]">{sidebar}</div>
        </MobileDrawer>

        {loading && (
          <div className="w-full">
            <Loading />
          </div>
        )}
        {!loading && (
          <div className="w-full grid gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 min-[1500px]:grid-cols-3 min-[2100px]:grid-cols-4 min-[2500px]:grid-cols-5 min-[3000px]:grid-cols-6 pb-5">
            {children}
          </div>
        )}
      </div>
    );
  }

  if (loading) return <Loading />;

  return (
    <div className="grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 min-[1500px]:grid-cols-4 min-[2100px]:grid-cols-5 min-[2500px]:grid-cols-6 min-[3000px]:grid-cols-7 pb-5">
      {children}
    </div>
  );
};

export default TilesGrid;
