import React from 'react';
import { Outlet } from 'react-router-dom';
import useTheme from '../../context/theme/useTheme';
import useIsMobile from '../../helper/useIsMobile';
import { ThemeStyles } from '../base/Illustration';
import Header from './Header';
import MobileHeader from './MobileHeader';

const Layout = () => {
  const { colors } = useTheme();

  const isMobile = useIsMobile();

  return (
    <div
      className="flex flex-col min-h-[100vh] p-5 pt-[56px] lg:pt-4 pb-0"
      style={{
        background: `linear-gradient(to bottom right, #F1F2F5, ${colors.secondary})`,
      }}
    >
      <ThemeStyles />
      {isMobile && <MobileHeader />}
      {isMobile === false && <Header />}
      <Outlet />
    </div>
  );
};

export default Layout;
