import {
  faCalendarArrowDown,
  faPenToSquare,
  faSidebar,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListTaskFragment, useGetTasksQuery } from '../../graphql/schema';
import Loading from '../base/Loading';
import MobileDrawer from '../layout/MobileDrawer';
import TasksSidebarEntry from './TasksSidebarEntry';

interface Props {
  activeTaskId?: string;
}

interface Group {
  id: string;
  label: string;
  tasks: ListTaskFragment[];
}

interface Groups {
  today: Group;
  yesterday: Group;
  thisWeek: Group;
  [key: string]: Group;
}

const TasksSidebar = ({ activeTaskId }: Props) => {
  const navigate = useNavigate();

  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { data, loading, fetchMore } = useGetTasksQuery();

  const groupedTasks = useMemo(() => {
    const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');

    const groups: Groups = {
      today: {
        id: 'today',
        label: 'Heute',
        tasks: [],
      },
      yesterday: {
        id: 'yesterday',
        label: 'Gestern',
        tasks: [],
      },
      thisWeek: {
        id: 'thisWeek',
        label: 'Diese Woche',
        tasks: [],
      },
    };

    data?.tasks.items.forEach((task) => {
      const createdAt = dayjs(task.createdAt);

      if (createdAt.isSame(today, 'day')) {
        groups.today.tasks.push(task);
      } else if (createdAt.isSame(yesterday, 'day')) {
        groups.yesterday.tasks.push(task);
      } else if (createdAt.isSame(today, 'week')) {
        groups.thisWeek.tasks.push(task);
      } else {
        const monthId = createdAt.format('YYYY-MM');
        if (!groups[monthId])
          groups[monthId] = {
            id: createdAt.format('YYYY-MM'),
            label: createdAt.format('MMMM YYYY'),
            tasks: [],
          };
        groups[monthId].tasks.push(task);
      }
    });

    return Object.values(groups);
  }, [data]);

  const handleLoadMore = useCallback(async () => {
    if (!data?.tasks.nextToken) return;

    setLoadingMore(true);
    await fetchMore({
      variables: {
        options: {
          startingToken: data?.tasks.nextToken,
        },
      },
    });
    setLoadingMore(false);
  }, [data, fetchMore]);

  if (loading)
    return (
      <div className="flex flex-col w-[250px]">
        <Loading />
      </div>
    );

  if (data?.tasks.items.length === 0) return null;

  return (
    <MobileDrawer
      extras={
        <div className="p-4">
          <FontAwesomeIcon
            icon={faPenToSquare}
            className="cursor-pointer"
            onClick={() => navigate('/createTask')}
          />
        </div>
      }
    >
      <div className="flex flex-col lg:w-1/5 h-full lg:h-auto">
        <div className="lg:flex flex-row justify-between mb-2 hidden">
          <div className="space-x-5">
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="cursor-pointer"
              onClick={() => navigate('/createTask')}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: 'auto',
            flex: '1 1 auto',
            height: 0,
          }}
        >
          <div className="flex flex-col">
            {groupedTasks
              .filter((g) => g.tasks.length > 0)
              .map((group) => (
                <div key={group.id} className="space-y-1 mb-4">
                  <div className="text-sm font-semibold">{group.label}</div>
                  {group.tasks.map((task) => (
                    <TasksSidebarEntry
                      task={task}
                      key={task.id}
                      activeTaskId={activeTaskId}
                    />
                  ))}
                </div>
              ))}

            {data?.tasks.nextToken && !loadingMore && (
              <div
                onClick={handleLoadMore}
                className="flex justify-center space-x-2 text-sm items-center cursor-pointer mb-3"
              >
                <FontAwesomeIcon icon={faCalendarArrowDown} />
                <span>Ältere anzeigen</span>
              </div>
            )}
            {data?.tasks.nextToken && loadingMore && <Loading />}
          </div>
        </div>
      </div>
    </MobileDrawer>
  );
};

export default TasksSidebar;
