import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { SENTRY_DSN } from '../../constants';
import useUser from '../../helper/useUser';
import { FeedbackContext } from './feedbackContext';

const FeedbackProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();

  useEffect(() => {
    if (SENTRY_DSN) {
      if (user?.profile.email) {
        Sentry.setUser({
          fullName: user.profile.name ?? user.profile.sub,
          email: user.profile.email,
        });
      } else {
        Sentry.setUser({});
      }
    }
  }, [user]);

  return (
    <FeedbackContext.Provider value={{}}>{children}</FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
