import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { FavoriteList, TaskList } from '../../graphql/schema';
import { ApiContext } from './apiContext';
import generateLink from './generateLink';

const apolloClient = new ApolloClient({
  link: generateLink(),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tasks: {
            keyArgs: false,
            merge(existing: TaskList | undefined, incoming: TaskList) {
              if (!existing) return incoming;
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
              } satisfies TaskList;
            },
          },
          favorites: {
            keyArgs: false,
            merge(existing: FavoriteList | undefined, incoming: FavoriteList) {
              if (!existing) return incoming;
              return {
                ...incoming,
                items: [...existing.items, ...incoming.items],
              } satisfies FavoriteList;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const ApiProvider = ({ children }: Props) => {
  const { user, events, signinSilent } = useAuth();

  useEffect(() => {
    apolloClient.resetStore();
  }, [user]);

  useEffect(() => {
    return events.addAccessTokenExpiring(async () => {
      await signinSilent();
    });
  }, [events, signinSilent]);

  return (
    <ApiContext.Provider value={undefined}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
