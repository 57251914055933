import { message } from 'antd';
import React, { useCallback, useRef } from 'react';
import { TaskPromptFormFields } from '../../components/tasks/TaskPrompt';
import { STREAM_API_URL } from '../../constants';
import { TaskMessage, useCreateTaskMutation } from '../../graphql/schema';
import useStreamingMessageRequest from '../../stream/useStreamingMessageRequest';
import NewTaskMessage from './newTaskMessage';
import { TaskManagerContext } from './taskManagerContext';

const TaskManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const newTaskMessage = useRef<NewTaskMessage | undefined>(undefined);

  const [createTaskMutation] = useCreateTaskMutation();

  const warmUpStreamingApi = useCallback(() => {
    fetch(STREAM_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'ping',
      }),
    });
  }, []);

  const createTask = useCallback(
    async (taskDefinitionId?: string, gatewayId?: string) => {
      warmUpStreamingApi();

      const task = await createTaskMutation({
        variables: {
          dto: {
            taskDefinitionId,
            gatewayId,
          },
        },
        refetchQueries: ['GetTasks'],
      });

      if (!task.data?.createTask) {
        message.error(
          'Fehler beim Erstellen der Aufgabe. Bitte versuchen Sie es erneut!',
        );
        return undefined;
      }

      return task.data.createTask;
    },
    [createTaskMutation, warmUpStreamingApi],
  );

  const createTaskFromTaskDefinition = useCallback(
    async (taskDefinitionId: string, gatewayId: string, formValues: any) => {
      const task = await createTask(taskDefinitionId, gatewayId);
      if (!task) return;

      newTaskMessage.current = {
        taskId: task.id,
        formValues,
      };

      return task;
    },
    [createTask],
  );

  const createTaskFromPrompt = useCallback(
    async (prompt: TaskPromptFormFields) => {
      const task = await createTask();
      if (!task) return;

      newTaskMessage.current = {
        taskId: task.id,
        prompt,
      };

      return task;
    },
    [createTask],
  );

  const claimNewTaskMessage = () => {
    const msg = newTaskMessage.current;
    newTaskMessage.current = undefined;
    return msg;
  };

  return (
    <TaskManagerContext.Provider
      value={{
        createTaskFromTaskDefinition,
        createTaskFromPrompt,
        claimNewTaskMessage,
      }}
    >
      {children}
    </TaskManagerContext.Provider>
  );
};

export default TaskManagerProvider;
