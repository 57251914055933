import { faCalendarArrowDown, faHouse } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import Loading from '../components/base/Loading';
import PageHeader from '../components/base/PageHeader';
import FavoritesSidebar, {
  FavoritesSidebarType,
} from '../components/tiles/FavoritesSidebar';
import TaskDefinitionTile from '../components/tiles/TaskDefinitionTile';
import TaskTile from '../components/tiles/TaskTile';
import TilesGrid from '../components/tiles/TilesGrid';
import { useGetFavoritesQuery } from '../graphql/schema';

const Favorites = () => {
  const [type, setType] = useState<FavoritesSidebarType>();

  const { data, loading, fetchMore } = useGetFavoritesQuery({});

  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const handleLoadMore = useCallback(async () => {
    if (!data?.favorites.nextToken) return;

    setLoadingMore(true);
    await fetchMore({
      variables: {
        options: {
          startingToken: data?.favorites.nextToken,
        },
      },
    });
    setLoadingMore(false);
  }, [data, fetchMore]);

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            icon: faHouse,
            link: '/',
          },
          {
            label: 'Favoriten',
          },
        ]}
      />
      <TilesGrid
        loading={loading}
        sidebar={<FavoritesSidebar type={type} onChangeType={setType} />}
      >
        {!loading &&
          data?.favorites.items
            .filter(
              (favorite) =>
                favorite.task?.isFavorite ||
                favorite.taskDefinition?.isFavorite,
            )
            .filter((favorite) => {
              if (!type) return true;
              if (
                type === FavoritesSidebarType.TaskDefinition &&
                favorite.taskDefinition
              )
                return true;
              if (type === FavoritesSidebarType.Task && favorite.task)
                return true;
              return false;
            })
            .map((favorite) => {
              if (favorite.taskDefinition) {
                return (
                  <TaskDefinitionTile
                    taskDefinition={favorite.taskDefinition}
                    key={favorite.taskDefinitionId}
                  />
                );
              }

              if (favorite.task) {
                return <TaskTile task={favorite.task} key={favorite.taskId} />;
              }

              return null;
            })}
      </TilesGrid>

      {data?.favorites.nextToken && !loadingMore && (
        <div
          onClick={handleLoadMore}
          className="flex justify-center space-x-2 text-sm items-center cursor-pointer mb-3"
        >
          <FontAwesomeIcon icon={faCalendarArrowDown} />
          <span>Mehr anzeigen</span>
        </div>
      )}
      {data?.favorites.nextToken && loadingMore && <Loading />}
    </>
  );
};

export default Favorites;
