import { Spin } from 'antd';
import React from 'react';

interface Props {
  message?: string;
}

const Loading = ({ message }: Props) => {
  return (
    <div className="w-full text-center mt-10 flex flex-col space-y-5">
      <Spin />
      {message && <span>{message}</span>}
    </div>
  );
};
export default Loading;
