import { faCopy, faPencil } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Skeleton, notification } from 'antd';
import React from 'react';
import useTheme from '../../context/theme/useTheme';
import { FormFieldDefinition, TaskMessage } from '../../graphql/schema';
import FormattedMessage from '../base/FormattedMessage/formattedMessage';
import Logo from '../base/Logo';
import FilePreview from '../base/upload/FilePreview';
import FilePreviewContainer from '../base/upload/FilePreviewContainer';
import fields from '../formBuilder/fields';
import MessageSources from './MessageSources';

export type TaskMessageWithLoadingIndicator = TaskMessage & {
  loading?: boolean;
};

interface Props {
  message: TaskMessageWithLoadingIndicator;
  onEditMessage?: (message: TaskMessage) => void;
  formFieldDefinitions: FormFieldDefinition[];
}

const Message = ({ message, onEditMessage, formFieldDefinitions }: Props) => {
  const { colors } = useTheme();

  const formFields = message.input.formValues
    ? formFieldDefinitions.filter((formField) => {
        const field = fields[formField.type as any];

        return (
          field &&
          message.input.formValues[formField.name] !== undefined &&
          message.input.formValues[formField.name] !== null
        );
      })
    : [];

  return (
    <div className="w-full flex flex-col space-y-6">
      {message.input.followUpPrompt && (
        <div className="w-2/4 self-end flex flex-row items-start">
          <div className="w-full bg-white bg-opacity-50 rounded-xl p-3 text-sm lg:ml-1">
            {message.input.followUpPrompt?.label}
          </div>
        </div>
      )}

      {message.input.prompt && (
        <div className="w-full lg:w-3/4 self-end flex flex-col lg:flex-row items-start">
          <div className="opacity-40 hover:opacity-100">
            {onEditMessage && (
              <Button
                type="text"
                onClick={() => onEditMessage(message)}
                className="rounded-full"
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            )}
          </div>
          <div className="w-full bg-white bg-opacity-50 rounded-xl p-3 text-sm lg:ml-1">
            {Object.values(message.input.prompt)
              .filter((v) => typeof v === 'string')
              .join('\n')}

            <FilePreviewContainer>
              {Object.values(message.input.prompt)
                .filter(
                  (v: any) =>
                    Array.isArray(v) || (typeof v === 'object' && v.id),
                )
                .map((file: any) => {
                  if (Array.isArray(file))
                    return (
                      <>
                        {file.map((v: any) => (
                          <FilePreview file={v} key={v.id} />
                        ))}
                      </>
                    );

                  return <FilePreview file={file} key={file.id} />;
                })}
            </FilePreviewContainer>
          </div>
        </div>
      )}

      {message.input.formValues && (
        <div className="w-full lg:w-3/4 self-end flex flex-col lg:flex-row items-start">
          <div className="opacity-40 hover:opacity-100">
            {onEditMessage && (
              <Button
                type="text"
                onClick={() => onEditMessage(message)}
                className="rounded-full"
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            )}
          </div>
          <div className="w-full bg-white bg-opacity-50 rounded-xl p-3 text-sm lg:ml-1">
            {formFields.map((formField) => {
              const field = fields[formField.type];

              return (
                <div key={formField.name}>
                  <strong>{formField.label}: </strong>
                  {field.renderReadOnly(
                    message.input.formValues[formField.name],
                    formField,
                  )}
                </div>
              );
            })}
            {formFields.length === 0 && (
              <div className="italic">Keine Informationen zur Anfrage</div>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col lg:flex-row items-start">
        <div className="rounded-full hidden lg:flex w-[30px] h-[30px] items-center justify-center mr-4 theme-bg-primary">
          <Logo color={colors.primaryText} width={15} />
        </div>
        <div className="w-full lg:w-3/4 self-start bg-white rounded-xl p-3 text-sm mr-1">
          {message.loading && !message.response?.text && (
            <Skeleton
              active
              round
              paragraph={{
                rows: 2,
              }}
            />
          )}

          {message.loading && message.response?.text && (
            <div className="prose w-full max-w-full text-base">
              <FormattedMessage text={message.response.text} />{' '}
              <FontAwesomeIcon icon={faCircle} color="#ccc" />
            </div>
          )}

          {!message.loading && message.response?.text && (
            <div className="prose w-full max-w-full text-base">
              <FormattedMessage text={message.response.text} />
            </div>
          )}

          {!message.loading && message.response?.sources && (
            <MessageSources sources={message.response?.sources} />
          )}

          {!message.loading && message.response?.files && (
            <div className="flex flex-row space-x-2 w-full">
              {message.response.files
                .filter((file) => !!file)
                .map((file) => (
                  <FilePreview file={file} key={file.id} />
                ))}
            </div>
          )}
        </div>
        <div className="flex flex-row opacity-40 hover:opacity-100 self-end">
          {!message.loading && (
            <Button
              type="text"
              onClick={() => {
                navigator.clipboard.writeText(message.response?.text ?? '');
                notification.info({
                  message: 'Die Nachricht wurde in die Zwischenablage kopiert',
                });
              }}
              className="rounded-full"
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
