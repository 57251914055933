import { TaskMessage } from '../graphql/schema';
import useStreamingMessageRequest from './useStreamingMessageRequest';

const useCreateMessage = (taskId?: string) => {
  const streamingMessageRequest = useStreamingMessageRequest();

  return async (
    formValues: any,
    onReceiveTextChunk: (chunk: string) => void,
  ): Promise<TaskMessage> => {
    if (!taskId) throw new Error('Missing task ID');

    return streamingMessageRequest<TaskMessage>(
      'createTaskMessage',
      taskId,
      formValues,
      onReceiveTextChunk,
    );
  };
};

export default useCreateMessage;
