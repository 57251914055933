import React from 'react';
import { TaskPromptFormFields } from '../../components/tasks/TaskPrompt';
import { Task } from '../../graphql/schema';
import NewTaskMessage from './newTaskMessage';

interface TaskManagerContextProps {
  createTaskFromTaskDefinition: (
    taskDefinitionId: string,
    gatewayId: string,
    formValues: any,
  ) => Promise<Task | undefined>;
  createTaskFromPrompt: (
    prompt: TaskPromptFormFields,
  ) => Promise<Task | undefined>;
  claimNewTaskMessage: () => NewTaskMessage | undefined;
}

export const TaskManagerContext = React.createContext<TaskManagerContextProps>({
  createTaskFromTaskDefinition: async () => undefined,
  createTaskFromPrompt: async () => undefined,
  claimNewTaskMessage: () => undefined,
});
