import { Form, FormInstance, FormProps, Modal } from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import { ReactPortal, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props<T> {
  title: string;
  okText: string;
  cancelText: string;
  okType?: LegacyButtonType;
  width?: number;
  renderForm: (form: FormInstance<T>, formProps: FormProps) => JSX.Element;
  content?: any;
}

function useFormModal<T = any>(
  props: Props<T>,
): [ReactPortal, (initFields?: T) => Promise<T | undefined>] {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<T>();
  const [form] = Form.useForm();
  const promiseResolver =
    useRef<(value: T | undefined | PromiseLike<T | undefined>) => void>();
  const portalRef = useRef<HTMLDivElement | null>(null);

  const handleOk = useCallback(async () => {
    try {
      const values = await form.validateFields();
      promiseResolver.current?.(values);
      setIsOpen(false);
    } catch (e) {}
  }, [form]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
    promiseResolver.current?.(undefined);
  }, []);

  useEffect(() => {
    if (isOpen && portalRef.current) {
      form.resetFields();
      if (initialValues) form.setFieldsValue(initialValues);
      setTimeout(() => {
        portalRef.current?.querySelector('input')?.focus();
      }, 1);
    }
  }, [isOpen, form, initialValues]);

  const portal = createPortal(
    <Modal
      open={isOpen}
      title={props.title}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={props.okText}
      cancelText={props.cancelText}
    >
      <div ref={portalRef}>
        {props.content}
        {props.renderForm(form, {
          onFinish: () => handleOk(),
        })}
      </div>
    </Modal>,
    document.body,
  );

  return [
    portal,
    (initFields?: T) => {
      setInitialValues(initFields);
      setIsOpen(true);
      return new Promise<T | undefined>((resolve) => {
        promiseResolver.current = resolve;
      });
    },
  ];
}

export default useFormModal;
