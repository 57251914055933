import { UploadContentType } from '@app/shared';
import { faFileUpload, faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useCallback, useMemo } from 'react';
import {
  File as ApiFile,
  Capability,
  TemplateFieldRole,
} from '../../graphql/schema';
import { supportedImageContentTypes } from '../../helper/files';
import FilePreview from '../base/upload/FilePreview';
import FilePreviewContainer from '../base/upload/FilePreviewContainer';
import Upload from '../base/upload/Upload';

interface Props {
  value?: (RcFile | ApiFile)[];
  onChange?: (val: (RcFile | ApiFile)[]) => void;
  capability?: Capability;
}

const TaskPromptUpload = ({ value, onChange, capability }: Props) => {
  const hasImageUpload = useMemo(() => {
    return (
      !capability ||
      !!capability.messageTemplateFields.find(
        (field) => field.role === TemplateFieldRole.IMAGES,
      )
    );
  }, [capability]);

  const hasFileUpload = useMemo(() => {
    return (
      !capability ||
      !!capability.messageTemplateFields.find(
        (field) => field.role === TemplateFieldRole.IMAGES,
      )
    );
  }, [capability]);

  const handleDelete = useCallback(
    (index: number) => {
      return () => {
        if (onChange) onChange(value?.filter((_, i) => index !== i) ?? []);
      };
    },
    [value, onChange],
  );

  if (!hasImageUpload && !hasFileUpload) return null;

  return (
    <div>
      {value && value.length > 0 && (
        <FilePreviewContainer className="mb-2">
          {value.map((file, index) => (
            <FilePreview
              file={file}
              key={(file as any).id ?? (file as any).uid}
              onDelete={handleDelete(index)}
              disablePreview
            />
          ))}
        </FilePreviewContainer>
      )}

      <Upload
        value={value}
        onChange={onChange as any}
        maxCount={10}
        allowedMimeTypes={[
          ...(hasFileUpload ? Object.values(UploadContentType) : []),
          ...(hasImageUpload ? supportedImageContentTypes : []),
        ]}
      >
        <Button className="rounded-full h-[35px] border-gray-300 text-gray-600 border">
          <FontAwesomeIcon icon={!hasFileUpload ? faImage : faFileUpload} />
          {!hasFileUpload ? 'Bilder' : 'Dateien'} hinzufügen
        </Button>
      </Upload>
    </div>
  );
};

export default TaskPromptUpload;
