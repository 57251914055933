import React from 'react';
import { TaskDefinition } from '../../graphql/schema';
import Illustration from '../base/Illustration';

interface Props {
  taskDefinition: TaskDefinition;
}

const TaskDefinitionHeader = ({ taskDefinition }: Props) => {
  return (
    <div className="flex lg:space-x-4 mb-3">
      <div className="hidden lg:block w-1/4 max-w-[180px]">
        <Illustration id={taskDefinition.illustration} className="w-full" />
      </div>
      <div>
        <div className="text-lg font-semibold">{taskDefinition.title}</div>
        <p className="text-sm">{taskDefinition.description}</p>
      </div>
    </div>
  );
};

export default TaskDefinitionHeader;
