import { UploadContentType } from '@app/shared';
import { faDownload, faFile, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RcFile } from 'antd/es/upload';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { File as ApiFile } from '../../../graphql/schema';
import { getFileContentType, isImageFile } from '../../../helper/files';
import Image from '../Image';

interface Props {
  file?: ApiFile | RcFile;
  onDelete?: () => void;
  disablePreview?: boolean;
}

const scaleDownImage = async (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Resize image
      const img = document.createElement('img');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        const [MAX_WIDTH, MAX_HEIGHT] = [100, 100];

        let width = img.width;
        let height = img.height;

        if (img.width > MAX_WIDTH || img.height >= MAX_HEIGHT) {
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx2 = canvas.getContext('2d');
        ctx2?.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL('image/png'));
      };

      img.src = reader.result as string;
    };
  });
};

const FilePreview = ({ file, onDelete, disablePreview }: Props) => {
  const [previewImage, setPreviewImage] = useState<string>();

  const fileName = useMemo(() => {
    if (!file) return null;

    if (typeof file === 'object' && Object.hasOwn(file, 'fileName')) {
      // File from API
      return (file as ApiFile).fileName;
    }

    if (typeof file === 'object' && Object.hasOwn(file, 'uid')) {
      // File object
      return (file as RcFile).name;
    }

    return 'Unknown';
  }, [file]);

  const fileType = useMemo(() => {
    if (!file) return null;

    const contentType = getFileContentType(file);

    if (contentType === UploadContentType.CSV) return 'CSV';
    if (contentType === UploadContentType.PDF) return 'PDF';
    if (contentType === UploadContentType.DOCX) return 'DOCX';
    if (contentType === UploadContentType.DOC) return 'DOC';
    if (contentType === UploadContentType.PPTX) return 'PPTX';
    if (contentType === UploadContentType.XLSX) return 'XLSX';

    return '';
  }, [file]);

  useEffect(() => {
    if (!file || !isImageFile(file)) {
      setPreviewImage(undefined);
      return;
    }
    if (typeof file === 'object' && Object.hasOwn(file, 'fileName')) {
      // File from API
      setPreviewImage((file as ApiFile).thumbnailUrl ?? undefined);
    }

    if (typeof file === 'object' && Object.hasOwn(file, 'uid')) {
      // File object
      (async () => {
        setPreviewImage(await scaleDownImage(file as RcFile));
      })();
    }
  }, [file]);

  const handleDownload = useCallback(() => {
    if (!(file as any).url) return;

    fetch((file as any).url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<'a'>('a');
        link.href = url;
        link.download = fileName ?? 'file';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  }, [file, fileName]);

  if (previewImage) {
    return (
      <Image
        url={(file as any).url}
        previewUrl={previewImage}
        height={50}
        onDelete={onDelete}
        disablePreview={disablePreview}
      />
    );
  }

  return (
    <div className="relative border border-gray-300 rounded-xl p-1 flex flex-row space-x-2 max-w-[280px] justify-center h-[50px]">
      <div className="theme-bg-primary rounded-[0.6rem] flex justify-center items-center !w-[40px] !h-[40px] relative group">
        <FontAwesomeIcon icon={faFile} size="lg" />
        <div
          className="group-hover:flex hidden absolute top-0 left-0 right-0 bottom-0 cursor-pointer theme-bg-primary rounded-[0.6rem] justify-center items-center"
          onClick={handleDownload}
        >
          <FontAwesomeIcon icon={faDownload} size="lg" />
        </div>
      </div>
      <div className="flex flex-col w-[130px] lg:w-[220px] justify-between py-1">
        <div className="font-semibold tracking-tight truncate leading-none">
          {fileName}
        </div>
        <div className="text-gray-500 leading-none">{fileType}</div>
      </div>
      {onDelete && (
        <div
          className="cursor-pointer absolute right-[-4px] top-[-4px] theme-bg-primary rounded-full w-[16px] h-[16px] flex justify-center items-center"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </div>
      )}
    </div>
  );
};

export default FilePreview;
