import { HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { API_URL } from '../../constants';
import getToken from './getToken';

const generateLink = () => {
  const httpConnectionLink = new HttpLink({
    uri: API_URL,
  });

  const authLink = setContext((_, previousContext) => {
    const savedCompanyId = window.localStorage.getItem('companyId');
    const token = getToken();

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        authorization: `Bearer ${token}`,
        ...(savedCompanyId
          ? {
              'x-company-id': savedCompanyId,
            }
          : {}),
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (!graphQLErrors) return;
    },
  );

  return from([errorLink, authLink, httpConnectionLink]);
};

export default generateLink;
