import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import useIsMobile from '../../helper/useIsMobile';

interface Props {
  onSearch?: (query: string) => void;
  searchPlaceholder?: string;
  breadcrumbs: {
    label?: string | ReactNode;
    icon?: any;
    link?: string;
  }[];
}

const PageHeader = ({ breadcrumbs }: Props) => {
  const isMobile = useIsMobile();

  return (
    <div className="w-full mb-4 mt-4 lg:mt-0">
      <div className="flex flex-row space-x-4 ">
        {breadcrumbs
          .filter(({ label, icon }) => label || icon)
          .map(({ link, label, icon }, index) => (
            <div
              className="flex flex-row space-x-4 items-center text-xs lg:text-base"
              key={`${link}-${label}`}
            >
              <Link
                to={link ?? '#'}
                className={
                  index === breadcrumbs.length - 1 ? 'font-semibold' : ''
                }
              >
                <Space>
                  {icon && <FontAwesomeIcon icon={icon} />}
                  <span>{label}</span>
                </Space>
              </Link>
              {index < breadcrumbs.length - 1 && (
                <FontAwesomeIcon icon={faChevronRight} size="sm" />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PageHeader;
