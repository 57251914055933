import {
  faCogs,
  faComment,
  faHouse,
  faSearch,
  faStar,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '../../graphql/schema';
import useUser from '../../helper/useUser';

const Navigation = () => {
  const { pathname } = useLocation();

  const elements = [
    {
      label: 'Übersicht',
      icon: faHouse,
      link: '/',
      prefixes: ['/module/', '/createTask/'],
    },
    {
      label: 'Meine Aktivitäten',
      icon: faComment,
      link: '/tasks',
      prefixes: ['/tasks/', '/task/'],
    },
    {
      label: 'Favoriten',
      icon: faStar,
      link: '/favorites',
    },
    {
      label: 'Suche',
      icon: faSearch,
      link: '/search',
      prefixes: ['/search'],
    },
  ];

  return (
    <div className="flex space-x-2">
      {elements.map(({ link, label, icon, prefixes }) => {
        const active = prefixes
          ? pathname === link || prefixes.find((p) => pathname.startsWith(p))
          : pathname.startsWith(link);
        return (
          <Link
            key={link}
            to={link}
            className={`theme-bg-primary-hover cursor-pointer text p-2 px-3 space-x-2 rounded-full ${active ? 'font-bold' : ''}`}
          >
            <FontAwesomeIcon icon={icon} />
            <span>{label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
