interface Props {
  children: any;
  className?: string;
}

const FilePreviewContainer = ({ children, className }: Props) => {
  return (
    <div className={`flex flex-wrap flex-row gap-2 ${className}`}>
      {children}
    </div>
  );
};

export default FilePreviewContainer;
