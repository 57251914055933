import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useTaskManager from '../../context/taskManager/useTaskManager';
import FancyButton from '../base/FancyButton';
import TaskPrompt, { TaskPromptFormFields } from './TaskPrompt';

const GenericTask = () => {
  const navigate = useNavigate();
  const { createTaskFromPrompt } = useTaskManager();

  const [loading, setLoading] = useState<boolean>(false);

  const [promptForm] = Form.useForm<TaskPromptFormFields>();

  const addMessageFromForm = useCallback(
    async (prompt: TaskPromptFormFields) => {
      setLoading(true);
      const task = await createTaskFromPrompt(prompt);
      setLoading(false);

      if (task) navigate(`/task/${task.id}`);
    },
    [navigate, createTaskFromPrompt],
  );

  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <div className="w-full space-y-3">
        <div className="w-full text-2xl font-semibold text-center">
          Wie kann ich helfen?
        </div>
        <TaskPrompt
          form={promptForm}
          onSend={addMessageFromForm}
          loading={loading}
          focusOnLoad
        />
        <div className="flex w-full space-y-2 flex-col items-center">
          <div className="text-sm">oder</div>
          <Link to="/">
            <FancyButton
              type="dashed"
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              iconPosition="end"
            >
              Aufgaben entdecken
            </FancyButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GenericTask;
