import { Form, notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useTaskManager from '../../context/taskManager/useTaskManager';
import {
  File as ApiFile,
  Capability,
  FollowUpPrompt,
  Task,
  TaskDefinition,
  TaskMessage,
  TaskMessageInput,
  TemplateFieldRole,
} from '../../graphql/schema';
import { isImageFile } from '../../helper/files';
import uploadFormFiles from '../../helper/uploadFormFiles';
import useCreateGenericMessage from '../../stream/useCreateGenericMessage';
import useCreateMessage from '../../stream/useCreateMessage';
import useCreateMessageWithFollowUpPrompt from '../../stream/useCreateMessageWithFollowUpPrompt';
import EditMessage from './EditMessage';
import FollowUpPrompts from './FollowUpPrompts';
import Message, { TaskMessageWithLoadingIndicator } from './Message';
import TaskDefinitionBreadcrumbs from './TaskDefinitionBreadcrumbs';
import TaskDefinitionHeader from './TaskDefinitionHeader';
import TaskPrompt, { TaskPromptFormFields } from './TaskPrompt';

interface Props {
  task: Task;
  capability?: Capability;
  taskDefinition?: TaskDefinition;
  reloadTask: () => void;
}

const TaskView = ({ task, taskDefinition, reloadTask, capability }: Props) => {
  const { claimNewTaskMessage } = useTaskManager();

  const [newMessagePlaceholder, setNewMessagePlaceholder] =
    useState<TaskMessageWithLoadingIndicator>();

  const [promptForm] = Form.useForm<TaskPromptFormFields>();

  const [selectedMessage, setSelectedMessage] = useState<TaskMessage>();

  const createMessage = useCreateMessage(task.id);
  const createMessageUsingFollowUpPrompt = useCreateMessageWithFollowUpPrompt(
    task.id,
  );
  const createMessageUsingGenericPrompt = useCreateGenericMessage(task.id);

  const loadedTaskId = useRef<string | undefined>();
  const numberOfLoadedMessages = useRef<number | undefined>();

  const scrollToBottom = useCallback(() => {
    window.document.getElementById('messagesContainer')?.scrollTo({
      top: 999999999,
    });
  }, []);

  useEffect(() => {
    // Scroll down when messages change
    scrollToBottom();
  }, [task.messages, newMessagePlaceholder, scrollToBottom]);

  useEffect(() => {
    loadedTaskId.current = task.id;

    if (
      task.id !== loadedTaskId.current ||
      numberOfLoadedMessages.current !== task.messages?.length
    ) {
      // Task changed or new messages are available
      numberOfLoadedMessages.current = task.messages?.length;

      // Reset on task change
      setSelectedMessage(undefined);

      // Reset placeholder
      setNewMessagePlaceholder(undefined);
    }

    const newTaskMessage = claimNewTaskMessage();
    if (newTaskMessage) {
      // Handle new message requests passed from other pages
      if (newTaskMessage.taskId === task.id) {
        // Add message
        if (newTaskMessage.prompt)
          handleAddMessageUsingGenericPrompt(newTaskMessage.prompt);
        if (newTaskMessage.formValues)
          handleAddMessageUsingTaskDefinitionForm(newTaskMessage.formValues);
        if (newTaskMessage.followUpPrompt)
          handleAddMessageUsingFollowUpPrompt(newTaskMessage.followUpPrompt);
      }
    }
  }, [task, claimNewTaskMessage]);

  const addTextChunk = useCallback((textChunk: string) => {
    setNewMessagePlaceholder((message) =>
      message
        ? {
            ...message,
            response: {
              text: `${message.response?.text ?? ''}${textChunk}`,
            },
          }
        : message,
    );
  }, []);

  const addMessage = useCallback(
    async (input: TaskMessageInput) => {
      // Add placeholder message
      setNewMessagePlaceholder({
        id: 'NEW',
        createdAt: new Date(),
        input,
        response: {},
        loading: true, // TODO: "uploading"
      });

      try {
        // Create message
        let newMessage: TaskMessage | undefined = undefined;

        if (input.formValues) {
          newMessage = await createMessage(
            await uploadFormFiles(
              'task',
              input.formValues,
              task.fileUploadInfo,
            ),
            addTextChunk,
          );
        } else if (input.prompt) {
          newMessage = await createMessageUsingGenericPrompt(
            await uploadFormFiles('task', input.prompt, task.fileUploadInfo),
            addTextChunk,
          );
        } else if (input.followUpPrompt) {
          newMessage = await createMessageUsingFollowUpPrompt(
            input.followUpPrompt,
            addTextChunk,
          );
        }

        if (!newMessage) throw new Error('Failed to generate task message');

        reloadTask();

        setNewMessagePlaceholder(newMessage);
      } catch (error: any) {
        notification.error({
          message: error.message,
          closable: true,
          duration: null,
        });

        setNewMessagePlaceholder(undefined);
      }
    },
    [
      addTextChunk,
      createMessageUsingFollowUpPrompt,
      task,
      reloadTask,
      createMessage,
      createMessageUsingGenericPrompt,
    ],
  );

  const getMessageInputKeys = useCallback(() => {
    const imagesKey =
      capability?.messageTemplateFields.find(
        (field) => field.role === TemplateFieldRole.IMAGES,
      )?.name ?? 'images';
    const filesKey =
      capability?.messageTemplateFields.find(
        (field) => field.role === TemplateFieldRole.FILES,
      )?.name ?? 'files';
    const promptKey =
      capability?.messageTemplateFields.find(
        (field) => field.role === TemplateFieldRole.PROMPT,
      )?.name ?? 'prompt';

    return {
      imagesKey,
      filesKey,
      promptKey,
    };
  }, [capability]);

  const handleEditMessage = useCallback(
    (message: TaskMessage) => {
      const { formValues, followUpPrompt, prompt } = message.input;

      if (formValues) {
        setSelectedMessage(message);
      } else if (followUpPrompt) {
        // Not possible
      } else if (prompt) {
        // Find fields for images & files and move all items to files form field
        const files: ApiFile[] = [];

        const { imagesKey, filesKey, promptKey } = getMessageInputKeys();

        if (imagesKey && prompt[imagesKey]) {
          files.push(...prompt[imagesKey]);
        }
        if (filesKey && prompt[filesKey]) {
          files.push(...prompt[filesKey]);
        }

        promptForm.setFieldsValue({
          prompt: message.input.prompt[promptKey] ?? '',
          files,
        });
        document.getElementById('promptTextField')?.focus();
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    },
    [promptForm, getMessageInputKeys, scrollToBottom],
  );

  const handleAddMessageUsingGenericPrompt = useCallback(
    async (prompt: TaskPromptFormFields) => {
      const message: any = {};

      // Find message fields for images & files
      const { imagesKey, filesKey, promptKey } = getMessageInputKeys();

      const images = prompt.files?.filter((file) => isImageFile(file));
      const files = prompt.files?.filter((file) => !isImageFile(file));

      if (images?.length) message[imagesKey] = images;
      if (files?.length) message[filesKey] = files;

      message[promptKey] = prompt.prompt;

      await addMessage({
        prompt: message,
      });
    },
    [addMessage, getMessageInputKeys],
  );

  const handleAddMessageUsingTaskDefinitionForm = useCallback(
    async (formValues: any) => {
      addMessage({
        formValues,
      });
    },
    [addMessage],
  );

  const handleAddMessageUsingFollowUpPrompt = useCallback(
    async (followUpPrompt: FollowUpPrompt) => {
      addMessage({
        followUpPrompt,
      });
    },
    [addMessage],
  );

  return (
    <>
      {selectedMessage && taskDefinition && (
        <EditMessage
          message={selectedMessage}
          taskDefinition={taskDefinition}
          onClose={() => setSelectedMessage(undefined)}
          onSave={handleAddMessageUsingTaskDefinitionForm}
        />
      )}

      {!selectedMessage && (
        <>
          <div
            style={{
              overflowY: 'auto',
              flex: '1 1 auto',
              height: 0,
            }}
            className="mr-[-1.25rem] pr-5 pb-5"
            id="messagesContainer"
          >
            {taskDefinition && (
              <>
                <TaskDefinitionBreadcrumbs taskDefinition={taskDefinition} />
                <TaskDefinitionHeader taskDefinition={taskDefinition} />
              </>
            )}
            <div className="flex flex-col space-y-6">
              {task.messages?.map((message) => (
                <Message
                  message={message}
                  onEditMessage={handleEditMessage}
                  formFieldDefinitions={taskDefinition?.formDefinition ?? []}
                  key={message.id}
                />
              ))}
              {newMessagePlaceholder && (
                <Message
                  message={newMessagePlaceholder}
                  formFieldDefinitions={taskDefinition?.formDefinition ?? []}
                  key="NEW"
                />
              )}
            </div>

            <FollowUpPrompts
              task={task}
              onSelect={handleAddMessageUsingFollowUpPrompt}
            />
          </div>
          <TaskPrompt
            form={promptForm}
            onSend={handleAddMessageUsingGenericPrompt}
            capability={capability}
            hideIfInactive
          />
        </>
      )}
    </>
  );
};

export default TaskView;
