import {
  faComment,
  faCommentAltDots,
  faCommentLines,
  faConstruction,
  faList,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum FavoritesSidebarType {
  Task = 'Task',
  TaskDefinition = 'TaskDefinition',
}

interface Props {
  type?: FavoritesSidebarType;
  onChangeType: (type?: FavoritesSidebarType) => void;
}

const FavoritesSidebar = ({ type, onChangeType }: Props) => {
  return (
    <div className="flex flex-col space-y-2">
      {[
        {
          id: undefined,
          title: 'Alle',
          icon: faList,
        },
        {
          id: FavoritesSidebarType.Task,
          title: 'Aufgaben',
          icon: faComment,
        },
        {
          id: FavoritesSidebarType.TaskDefinition,
          title: 'Vorlagen',
          icon: faCommentLines,
        },
      ].map(({ id, title, icon }) => (
        <div
          key={id ?? 'all'}
          onClick={() => {
            onChangeType(id);
          }}
          className={`theme-bg-primary-hover ${type === id ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
        >
          <FontAwesomeIcon icon={icon} />
          <span>{title}</span>
        </div>
      ))}
    </div>
  );
};

export default FavoritesSidebar;
