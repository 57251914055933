import { faChevronDown, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Space } from 'antd';
import type { ItemType } from 'antd/es/menu/interface';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import useCompany from '../../context/company/useCompany';
import BugReportButton from '../base/BugReportButton';
import Logo from './Logo';
import Navigation from './Navigation';
import TenantSelect from './TenantSelect';
import useMenuItems from './useMenuItems';

const Header = () => {
  const { user } = useAuth();
  const menuItems = useMenuItems();

  return (
    <div className="flex w-full justify-between mb-4">
      <div className="w-[200px] flex items-center">
        <TenantSelect />
      </div>
      <div className="flex-1 flex justify-center items-center">
        <Navigation />
      </div>
      <div className="w-[200px] flex items-center justify-end space-x-5">
        <BugReportButton />
        <Dropdown
          menu={{
            items: [
              {
                key: 'username',
                label: user?.profile.email ?? '',
                disabled: true,
              },
              ...menuItems.map((item) => {
                if (!item.label) {
                  return {
                    type: 'divider',
                  } satisfies ItemType;
                }

                return {
                  key: item.key,
                  label: (
                    <Space>
                      {item.icon && (
                        <div className="w-5">
                          <FontAwesomeIcon icon={item.icon} />
                        </div>
                      )}
                      <span>{item.label}</span>
                    </Space>
                  ),
                  onClick: item.onClick,
                };
              }),
            ],
          }}
          trigger={['click']}
        >
          <a
            onClick={(e) => e.preventDefault()}
            className="flex items-center cursor-pointer space-x-2 bg-white rounded-full w-[40px] h-[40px] justify-center"
          >
            <FontAwesomeIcon icon={faUser} size="xs" />
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
