import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faSidebar,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import useIsMobile from '../../helper/useIsMobile';

interface Props {
  children: any;
  extras?: any;
}

const MobileDrawer = ({ children, extras }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isMobile = useIsMobile();

  if (!isMobile) return children;

  return (
    <>
      <div
        className="fixed top-0 left-0 p-5 py-4 z-[2000] cursor-pointer"
        onClick={() => setIsOpen((s) => !s)}
      >
        <FontAwesomeIcon icon={faSidebar} size="lg" />
      </div>

      {isOpen && (
        <div className="fixed flex-col flex top-0 left-0 bottom-0 bg-white z-[2001] shadow-xl w-[90%] max-w-[300px]">
          <div className="w-full flex cursor-pointer justify-between">
            <div onClick={() => setIsOpen(false)} className="p-4">
              <FontAwesomeIcon icon={faChevronLeft} color="#000" />
            </div>
            {extras && <div>{extras}</div>}
          </div>
          <div className="p-5 flex-1" onClick={() => setIsOpen(false)}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileDrawer;
