import { faKey } from '@fortawesome/pro-light-svg-icons';
import { Button, Card, Form, Input, notification } from 'antd';
import { updatePassword } from 'aws-amplify/auth';
import React, { useCallback } from 'react';
import PageHeader from '../components/base/PageHeader';
import formItemProps from '../helper/formItemProps';

interface FormFields {
  oldPassword: string;
  newPassword: string;
  passwordVerification: string;
}

const ChangePassword = () => {
  const [form] = Form.useForm<FormFields>();
  const [notifications, contextHolder] = notification.useNotification();

  const handleChangePassword = useCallback(
    async (formFields: FormFields) => {
      try {
        await updatePassword({
          oldPassword: formFields.oldPassword,
          newPassword: formFields.newPassword,
        });
        notifications.success({
          message: 'Ihr Passwort wurde geändert',
        });
        form.resetFields();
      } catch (error: any) {
        if (error.name === 'NotAuthorizedException') {
          notifications.error({
            message: 'Das eingegebene aktuelle Passwort ist nicht korrekt',
          });
          return;
        }
        console.error(JSON.stringify(error));
        notifications.error({
          message: 'Fehler beim Ändern des Passworts',
          description: 'Bitte versuchen Sie es erneut.',
        });
      }
    },
    [notifications, form],
  );

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            icon: faKey,
            label: 'Passwort ändern',
          },
        ]}
      />

      <Card>
        <Form
          form={form}
          onFinish={handleChangePassword}
          layout="vertical"
          autoComplete="off"
          className="max-w-[400px]"
        >
          <Form.Item
            {...formItemProps('oldPassword', 'Ihr bisheriges Passwort')}
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihr bisheriges Passwort ein',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            {...formItemProps('newPassword', 'Neues Passwort')}
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie ein neues Passwort ein',
              },
              {
                min: 8,
                message:
                  'Das neue Passwort muss mindestens 8 Zeichen lang sein',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            {...formItemProps(
              'passwordVerification',
              'Neues Passwort wiederholen',
            )}
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: 'Bitte wiederholen Sie das neue Passwort',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Die Passwörter stimmen nicht überein!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Passwort ändern
          </Button>
        </Form>

        {contextHolder}
      </Card>
    </>
  );
};

export default ChangePassword;
