import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Space } from 'antd';
import React from 'react';
import { TaskDefinition, TaskMessage } from '../../graphql/schema';
import Illustration from '../base/Illustration';
import TaskDefinitionForm from './TaskDefinitionForm';
import TaskDefinitionHeader from './TaskDefinitionHeader';

interface Props {
  message: TaskMessage;
  taskDefinition: TaskDefinition;
  onClose: () => void;
  onSave: (formValues: any) => void;
}

const EditMessage = ({ message, taskDefinition, onClose, onSave }: Props) => {
  return (
    <>
      <div className="text-left mb-2">
        <Button type="text" size="small" onClick={onClose}>
          <Space>
            <FontAwesomeIcon icon={faArrowLeft} />
            Zurück
          </Space>
        </Button>
      </div>
      <TaskDefinitionHeader taskDefinition={taskDefinition} />
      <TaskDefinitionForm
        schema={taskDefinition.formDefinition}
        onSubmit={onSave}
        values={message.input.formValues}
        saveButtonLabel="Weiter"
      />
    </>
  );
};

export default EditMessage;
