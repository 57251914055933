import { faPaperclip } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { useCallback } from 'react';
import FilePreview from '../base/upload/FilePreview';
import FilePreviewContainer from '../base/upload/FilePreviewContainer';
import Upload, { UploadProps } from '../base/upload/Upload';

const UploadButton = (props: Omit<UploadProps, 'children'>) => {
  const values =
    props.value === undefined
      ? undefined
      : Array.isArray(props.value)
        ? props.value
        : [props.value];

  const handleDelete = useCallback(
    (index: number) => {
      return () => {
        if (props.maxCount === undefined || props.maxCount === 1) {
          if (props.onChange) props.onChange(undefined);
        } else {
          if (Array.isArray(props.value)) {
            if (props.onChange) {
              props.onChange(props.value.filter((_, i) => index !== i));
            }
          }
        }
      };
    },
    [props.value, props.onChange, props.maxCount],
  );

  return (
    <div>
      <Upload {...props}>
        <Button
          icon={<FontAwesomeIcon icon={faPaperclip} />}
          className="rounded-[10px] border-0"
        >
          <span className="max-w-[200px] overflow-ellipsis overflow-hidden text-nowrap">
            {props.maxCount && props.maxCount > 1
              ? 'Dateien hinzufügen'
              : 'Datei auswählen'}
          </span>
        </Button>
      </Upload>

      {values && values.length > 0 && (
        <FilePreviewContainer className="mt-2">
          {values?.map((file, index) => (
            <FilePreview
              file={file}
              key={(file as any).id ?? (file as any).uid}
              onDelete={handleDelete(index)}
            />
          ))}
        </FilePreviewContainer>
      )}
    </div>
  );
};

export default UploadButton;
