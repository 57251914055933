import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { KeyValueStorageInterface } from 'aws-amplify/utils';
import { AuthProviderProps } from 'react-oidc-context';
import {
  COGNITO_AUTHORITY,
  COGNITO_CLIENT_ID,
  COGNITO_USER_POOL_ID,
} from './constants';
import getToken from './context/api/getToken';

export const cognitoAuthConfig: AuthProviderProps = {
  authority: COGNITO_AUTHORITY,
  client_id: COGNITO_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.host}/`,
  response_type: 'code',
  scope: 'email openid profile aws.cognito.signin.user.admin',
  onSigninCallback: (_: any) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  extraQueryParams: {
    lang: 'de',
  },
};

// Configure AWS Amplify SDK to use token from OIDC provider
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_CLIENT_ID,
    },
  },
});

class MyCustomStorage implements KeyValueStorageInterface {
  async setItem(key: string, value: string): Promise<void> {}
  async getItem(key: string): Promise<string | null> {
    if (key.endsWith('.accessToken')) return getToken();
    return null;
  }
  async removeItem(key: string): Promise<void> {}
  async clear(): Promise<void> {}
}

cognitoUserPoolsTokenProvider.setKeyValueStorage(new MyCustomStorage());
