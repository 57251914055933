import { Spin } from 'antd';
import React from 'react';

interface Props {
  message?: string;
}

const FullPageLoading = ({ message }: Props) => {
  return (
    <div className="flex w-full h-screen justify-center items-center content-center flex-col space-y-5">
      <Spin size="large" />
      {message && <span>{message}</span>}
    </div>
  );
};
export default FullPageLoading;
