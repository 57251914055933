import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import FullPageLoading from '../components/base/FullPageLoading';

const Login = () => {
  const { isLoading, isAuthenticated, signinRedirect } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      signinRedirect();
    }
  }, [isLoading, isAuthenticated, signinRedirect]);

  return <FullPageLoading />;
};

export default Login;
