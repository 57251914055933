import { TaskMessage } from '../graphql/schema';
import useStreamingMessageRequest from './useStreamingMessageRequest';

const useCreateGenericMessage = (taskId?: string) => {
  const streamingMessageRequest = useStreamingMessageRequest();

  return async (
    prompt: any,
    onReceiveTextChunk: (chunk: string) => void,
  ): Promise<TaskMessage> => {
    if (!taskId) throw new Error('Missing task ID');

    return streamingMessageRequest<TaskMessage>(
      'createGenericTaskMessage',
      taskId,
      prompt,
      onReceiveTextChunk,
    );
  };
};

export default useCreateGenericMessage;
