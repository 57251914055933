export const API_URL = import.meta.env.VITE_API_URI;

export const STREAM_API_URL = import.meta.env.VITE_STREAM_API_URL;

export const COGNITO_USER_POOL_ID = import.meta.env.VITE_COGNITO_USER_POOL_ID;

export const COGNITO_CLIENT_ID = import.meta.env.VITE_COGNITO_CLIENT_ID;

export const COGNITO_AUTHORITY = import.meta.env.VITE_COGNITO_AUTHORITY;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const VITE_DOMAIN = import.meta.env.VITE_DOMAIN;
