import { TaskMessageResponseSource } from '../../graphql/schema';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faDatabase,
  faExternalLink,
  faGlobe,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

interface Props {
  sources: TaskMessageResponseSource[];
}

const MessageSources = ({ sources }: Props) => {
  const [expanded, setExpanded] = useState(false);

  if (sources.length === 0) return null;

  return (
    <>
      <div className="mt-2">
        <div className="text-sm text-gray-500 space-x-2 border border-gray-300 rounded-2xl p-1 px-2 inline-block">
          <div
            className="space-x-1 flex flex-row items-center cursor-pointer"
            onClick={() => setExpanded((x) => !x)}
          >
            <FontAwesomeIcon icon={faGlobe} />
            <div>
              {sources.length} {sources.length > 1 ? 'Quellen' : 'Quelle'}
            </div>
          </div>
        </div>
      </div>

      {expanded && (
        <div className="fixed flex-col flex top-0 right-0 bottom-0 bg-white z-[2001] shadow-xl w-[90%] max-w-[500px]">
          <div className="w-full flex cursor-pointer justify-between">
            <div className="text-lg p-4">Quellen</div>
            <div onClick={() => setExpanded(false)} className="p-4">
              <FontAwesomeIcon icon={faTimes} color="#000" />
            </div>
          </div>
          <div className="p-5 space-y-5 flex-1 flex flex-col overflow-y-auto">
            {sources.map((source) => (
              <a
                className="w-full space-y-1 block"
                key={`${source.url}-${source.title}`}
                href={source.file?.url ?? source.url ?? ''}
                target="_blank"
              >
                <div className="space-x-1 text-xs">
                  <FontAwesomeIcon
                    icon={source.file ? faDatabase : faGlobe}
                    size="sm"
                  />
                  <span>
                    {source.file ? 'Unternehmensdaten' : 'Internetseite'}
                  </span>
                </div>
                <div className="font-semibold">{source.title}</div>
                {source.content && (
                  <div className="text-xs text-gray-500">{source.content}</div>
                )}
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MessageSources;
