import { STREAM_API_URL } from '../constants';
import getToken from '../context/api/getToken';

const useStreamingMessageRequest = () => {
  return async <Message>(
    type: string,
    taskId: string,
    payload: any,
    onReceiveTextChunk: (chunk: string) => void,
  ): Promise<Message> => {
    const token = getToken();
    if (!token) throw new Error('Missing access token');

    const companyId = window.localStorage.getItem('companyId');

    const response = await fetch(STREAM_API_URL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-company-id': companyId ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        taskId,
        payload,
      }),
    });

    if (!response.body || response.status !== 200)
      throw new Error('Invalid response');

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunkData = decoder.decode(value);

      if (chunkData.startsWith('ERROR:'))
        throw new Error(`Streaming response error: ${chunkData}`);

      if (chunkData.includes('___RESPONSE_END___')) {
        // Chunk contains response
        const [chunk, responseMessage] = chunkData.split('___RESPONSE_END___');
        onReceiveTextChunk(chunk);

        try {
          const parsedMessage = JSON.parse(responseMessage);

          if (parsedMessage.error) {
            throw new Error(parsedMessage.error);
          }
          return parsedMessage;
        } catch (e: any) {
          console.error(e);
          throw e;
        }
      } else {
        onReceiveTextChunk(chunkData);
      }
    }

    throw new Error('Missing final message response');
  };
};

export default useStreamingMessageRequest;
