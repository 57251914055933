import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Illustration from '../components/base/Illustration';
import Loading from '../components/base/Loading';
import ProviderSelector from '../components/tasks/ProviderSelector';
import TaskDefinitionBreadcrumbs from '../components/tasks/TaskDefinitionBreadcrumbs';
import TaskDefinitionForm from '../components/tasks/TaskDefinitionForm';
import useTaskManager from '../context/taskManager/useTaskManager';
import { Provider, useGetTaskDefinitionQuery } from '../graphql/schema';

const CreateTask = () => {
  const { taskDefinitionId } = useParams();
  const navigate = useNavigate();
  const { createTaskFromTaskDefinition } = useTaskManager();

  const { data, loading } = useGetTaskDefinitionQuery({
    variables: {
      id: taskDefinitionId ?? '',
    },
    skip: !taskDefinitionId,
    fetchPolicy: 'no-cache',
  });

  const [selectedProvider, setSelectedProvider] = useState<Provider>();
  const [createTaskLoading, setCreateTaskLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (formValues: any) => {
      if (!selectedProvider || !taskDefinitionId) return;

      setCreateTaskLoading(true);

      const task = await createTaskFromTaskDefinition(
        taskDefinitionId,
        selectedProvider.gatewayId,
        formValues,
      );

      if (task) navigate(`/task/${task.id}`);
    },
    [
      taskDefinitionId,
      selectedProvider,
      createTaskFromTaskDefinition,
      navigate,
    ],
  );

  useEffect(() => {
    if (
      data?.taskDefinition.usableProviders &&
      data?.taskDefinition.usableProviders.length > 0
    ) {
      setSelectedProvider(data?.taskDefinition.usableProviders[0]);
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <div>
      {data?.taskDefinition && (
        <TaskDefinitionBreadcrumbs taskDefinition={data.taskDefinition} />
      )}
      {data?.taskDefinition && (
        <>
          <div className="flex lg:space-x-4 mb-3 relative">
            <div className="w-1/4 max-w-[180px] hidden lg:block">
              <Illustration
                id={data.taskDefinition.illustration}
                className="w-full"
              />
            </div>
            <div>
              <div className="text-lg font-semibold">
                {data.taskDefinition.title}
              </div>
              <p className="text-sm">{data.taskDefinition.description}</p>
            </div>
            {data?.taskDefinition.usableProviders &&
              data.taskDefinition.usableProviders.length > 1 && (
                <div className="lg:absolute top-0 right-0">
                  <ProviderSelector
                    taskDefinition={data.taskDefinition}
                    selectProvider={setSelectedProvider}
                    selectedProvider={selectedProvider}
                  />
                </div>
              )}
          </div>

          <TaskDefinitionForm
            schema={data?.taskDefinition.formDefinition}
            onSubmit={handleSubmit}
            saveButtonLabel="Los geht's"
            loading={createTaskLoading}
          />
        </>
      )}
    </div>
  );
};

export default CreateTask;
