import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCompany from '../../context/company/useCompany';
import Logo from './Logo';

const TenantSelect = () => {
  const navigate = useNavigate();
  const { company, companies, changeCompany } = useCompany();

  if (companies.length > 1) {
    return (
      <Dropdown
        menu={{
          items: companies.map((company) => ({
            label: company.name,
            key: company.id,
            onClick: () => {
              changeCompany(company.id);
            },
          })),
        }}
        trigger={['click']}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className="flex items-center cursor-pointer space-x-2"
        >
          <Logo company={company} />
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </a>
      </Dropdown>
    );
  }

  return (
    <a onClick={() => navigate('/')} className="cursor-pointer">
      <Logo company={company} />
    </a>
  );
};

export default TenantSelect;
