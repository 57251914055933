import React from 'react';
import { FollowUpPrompt, Task } from '../../graphql/schema';

interface Props {
  task: Task;
  onSelect: (prompt: FollowUpPrompt) => void;
}

const FollowUpPrompts = ({ task, onSelect }: Props) => {
  if (!task.followUpPrompts || task.followUpPrompts.length === 0) return;

  return (
    <div className="mt-10">
      <div className="w-full text-xs text-center mb-2 opacity-60">
        Vorschläge:
      </div>
      <div className="w-full flex justify-center space-x-1 lg:space-x-4 space-y-1 flex-wrap">
        {task.followUpPrompts.map((prompt, index) => (
          <div
            key={index as any}
            onClick={() => onSelect(prompt)}
            className="text-xs rounded-full bg-white bg-opacity-60 p-2 px-3 cursor-pointer"
          >
            {prompt.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FollowUpPrompts;
