import { useCallback, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { Role } from '../graphql/schema';

const useUser = () => {
  const { user } = useAuth();

  const roles = useMemo(() => {
    if (!user) return [];
    return (user.profile['cognito:groups'] as Role[] | undefined) ?? [];
  }, [user]);

  const hasRole = useCallback(
    (requiredRoles: Role[]) => {
      for (const role of roles) {
        if (requiredRoles?.includes(role)) return true;
      }
      return false;
    },
    [roles],
  );

  return {
    roles,
    hasRole,
    user,
  };
};

export default useUser;
