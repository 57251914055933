import { RcFile } from 'antd/es/upload';
import { File as ApiFile } from '../graphql/schema';
import splitString from './splitString';

export const getFileNameFromFileId = (fileId: string) => {
  return splitString(fileId, '_', 2)[1];
};

export const supportedImageContentTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
];

export const getFileContentType = (file: ApiFile | RcFile | string) => {
  if (typeof file === 'string') {
    if (
      file.toLowerCase().endsWith('.jpg') ||
      file.toLowerCase().endsWith('.jpeg')
    )
      return 'image/jpeg';
    if (file.toLowerCase().endsWith('.png')) return 'image/png';
    if (file.toLowerCase().endsWith('.webp')) return 'image/webp';
  }

  return (file as any).contentType ?? (file as any).type ?? null;
};

export const isImageFile = (file: ApiFile | RcFile | string) => {
  const contentType = getFileContentType(file);
  console.log(supportedImageContentTypes.includes(contentType));
  return supportedImageContentTypes.includes(contentType);
};
