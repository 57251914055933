import { faDownload, faEye, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space } from 'antd';
import { Image as BaseImage } from 'antd';
import React, { useCallback } from 'react';
import FancyButton from './FancyButton';

interface Props {
  url: string;
  previewUrl?: string;
  height: number;
  onDelete?: () => void;
  disablePreview?: boolean;
}

const Image = ({
  url,
  previewUrl,
  height,
  onDelete,
  disablePreview,
}: Props) => {
  const handleImageDownload = useCallback(() => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<'a'>('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  }, [url]);

  if (disablePreview) {
    return (
      <div className="relative border border-gray-300 rounded-xl">
        <img
          src={url ?? previewUrl}
          className="ant-image-preview-img rounded-xl"
          style={{
            height,
          }}
        />
        {onDelete && (
          <div
            className="cursor-pointer absolute right-[-4px] top-[-4px] theme-bg-primary rounded-full w-[16px] h-[16px] flex justify-center items-center"
            onClick={onDelete}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative">
      <BaseImage
        src={previewUrl ?? url}
        height={height}
        className="border border-gray-300 rounded-xl"
        wrapperClassName="overflow-hidden"
        preview={{
          imageRender: () => (
            <img src={url ?? previewUrl} className="ant-image-preview-img" />
          ),
          maskClassName: 'rounded-xl',
          mask: (
            <div className="ant-image-mask-info">
              <FontAwesomeIcon icon={faEye} />
            </div>
          ),
          toolbarRender: (_, { image: { url } }) => (
            <Space size={12} className="toolbar-wrapper">
              <FancyButton
                onClick={handleImageDownload}
                icon={<FontAwesomeIcon icon={faDownload} />}
              >
                Herunterladen
              </FancyButton>
            </Space>
          ),
        }}
      />
      {onDelete && (
        <div
          className="cursor-pointer absolute right-[-4px] top-[-4px] theme-bg-primary rounded-full w-[16px] h-[16px] flex justify-center items-center"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTimes} size="sm" />
        </div>
      )}
    </div>
  );
};

export default Image;
