import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, FormInstance, Input } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useCallback, useEffect } from 'react';
import { File as ApiFile, Capability } from '../../graphql/schema';
import TaskPromptUpload from './TaskPromptUpload';

export interface TaskPromptFormFields {
  prompt: string;
  files: (ApiFile | RcFile)[];
}

interface Props {
  form: FormInstance<TaskPromptFormFields>;
  onSend: (prompt: TaskPromptFormFields) => Promise<void>;
  loading?: boolean;
  capability?: Capability;
  hideIfInactive?: boolean;
  focusOnLoad?: boolean;
}

const TaskPrompt = ({
  onSend,
  loading,
  form,
  capability,
  hideIfInactive,
  focusOnLoad,
}: Props) => {
  const handleSend = useCallback(
    async (formValues: TaskPromptFormFields) => {
      if (loading) return;
      form.resetFields();
      await onSend(formValues);
    },
    [onSend, loading, form],
  );

  useEffect(() => {
    if (focusOnLoad) {
      setTimeout(() => {
        document.getElementById('promptTextField')?.focus();
      }, 10);
    }
  }, [focusOnLoad]);

  const scrollToBottom = useCallback(() => {
    window.document.getElementById('messagesContainer')?.scrollTo({
      top: 999999999,
    });
  }, []);

  return (
    <div className="w-full justify-center flex mb-3">
      <Form
        form={form}
        onFinish={handleSend}
        className={`flex flex-col pt-3 w-full lg:w-2/3 relative rounded-2xl border-0 bg-white p-2 ${hideIfInactive ? 'opacity-60 hover:opacity-100 focus-within:opacity-100' : ''}`}
        onChange={scrollToBottom}
      >
        <Form.Item name="prompt" required noStyle>
          <Input.TextArea
            className="border-0 bg-white outline-0 focus:outline-0 focus:border-none focus:shadow-none focus-within:shadow-none"
            placeholder="Wie kann ich helfen?"
            id="promptTextField"
            autoSize={{ minRows: 2, maxRows: 15 }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && !e.shiftKey) {
                form.submit();
              }
            }}
            readOnly={loading}
          />
        </Form.Item>
        <div className="flex flex-row justify-between items-end">
          <Form.Item name="files" noStyle>
            <TaskPromptUpload capability={capability} />
          </Form.Item>
          <Button
            htmlType="submit"
            className="rounded-full border-0 h-[35px] !w-[35px] theme-bg-primary"
            loading={loading}
          >
            {!loading && <FontAwesomeIcon icon={faArrowRight} />}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default TaskPrompt;
