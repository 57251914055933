import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../components/base/Loading';
import GenericTask from '../components/tasks/GenericTask';
import TaskView from '../components/tasks/TaskView';
import TasksSidebar from '../components/tasks/TasksSidebar';
import { useGetTaskQuery } from '../graphql/schema';

const Task = () => {
  const { taskId } = useParams();

  const { data, loading, refetch } = useGetTaskQuery({
    variables: {
      id: taskId ?? '',
    },
    skip: !taskId,
    // fetchPolicy: 'no-cache',
  });

  return (
    <div className="flex flex-row lg:space-x-6 h-full flex-1">
      <TasksSidebar activeTaskId={data?.task.id} />
      <div className="flex flex-col flex-1">
        {loading && <Loading />}
        {!loading && !taskId && <GenericTask />}
        {data?.task && (
          <TaskView
            task={data.task}
            capability={data.capabilityForTask ?? undefined}
            taskDefinition={data.taskDefinitionForTask ?? undefined}
            reloadTask={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default Task;
