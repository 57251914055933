import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <Result status="success" title="Sie wurden abgemeldet" />
      <Button type="primary" onClick={() => navigate('/')}>
        Zur Anmeldung
      </Button>
    </div>
  );
};

export default Logout;
