import { faHouse } from '@fortawesome/pro-light-svg-icons';
import React, { useMemo } from 'react';
import {
  TaskDefinition,
  useGetModulesWithCategoriesQuery,
} from '../../graphql/schema';
import useIsMobile from '../../helper/useIsMobile';
import PageHeader from '../base/PageHeader';

interface Props {
  taskDefinition: TaskDefinition;
}

const TaskDefinitionBreadcrumbs = ({ taskDefinition }: Props) => {
  const isMobile = useIsMobile();

  const { data: modules, loading: modulesLoading } =
    useGetModulesWithCategoriesQuery();

  const [module, category] = useMemo(() => {
    if (modules) {
      for (const m of modules.modulesWithCategories) {
        if (m.categories) {
          for (const c of m.categories) {
            if (c.id === taskDefinition.categoryId) {
              return [m, c];
            }
          }
        }
      }
    }

    return [undefined, undefined];
  }, [taskDefinition, modules]);

  return (
    <PageHeader
      breadcrumbs={[
        {
          icon: faHouse,
          link: '/',
        },
        {
          label: module?.title,
          link: `/module/${module?.id}`,
        },
        {
          label: category?.title,
          link: `/module/${module?.id}`,
        },
        ...(!isMobile
          ? [
              {
                label: taskDefinition.title,
                link: '#',
              },
            ]
          : []),
      ]}
    />
  );
};

export default TaskDefinitionBreadcrumbs;
