import {
  faBars,
  faChevronRight,
  faCog,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import TenantSelect from './TenantSelect';
import useMenuItems from './useMenuItems';

const MobileHeader = () => {
  const menuItems = useMenuItems();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <>
      <div className="fixed top-0 right-0 left-0 py-2 flex w-full justify-between mb-4 bg-[#F1F2F5] z-[2000] shadow">
        <div className="flex items-center justify-end w-14 pl-5" />
        <div className="flex items-center">
          <TenantSelect />
        </div>
        <div
          className="flex items-center justify-end w-14 pr-5 cursor-pointer"
          onClick={() => setMenuOpen(true)}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </div>
      </div>

      {menuOpen && (
        <div className="fixed top-0 right-0 bottom-0 bg-white z-[2001] shadow-xl w-[90%] max-w-[300px]">
          <div
            className="w-full flex justify-end p-5 cursor-pointer"
            onClick={() => setMenuOpen(false)}
          >
            <FontAwesomeIcon icon={faChevronRight} color="#000" />
          </div>
          <div className="p-5">
            {menuItems.map((item) => {
              if (!item.label) {
                return <div key={item.key} className="mt-5" />;
              }

              return (
                <div
                  key={item.key}
                  className="flex flex-row justify-start items-center py-3 cursor-pointer"
                  onClick={() => {
                    if (item.onClick) item.onClick();
                    setMenuOpen(false);
                  }}
                >
                  {item.icon && (
                    <div className="w-9">
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                  )}
                  <span>{item.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileHeader;
