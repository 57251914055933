import { ApolloCache, gql } from '@apollo/client';
import { faSpinner, faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarFilled } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useCallback } from 'react';
import {
  Task,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} from '../../graphql/schema';
import Tile from './Tile';

interface Props {
  task: Task;
}

const TaskTile = ({ task }: Props) => {
  const [addFavorite, { loading: addFavoriteLoading }] =
    useAddFavoriteMutation();
  const [removeFavorite, { loading: removeFavoriteLoading }] =
    useRemoveFavoriteMutation();

  const handleToggleFavorite = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();

      const update = (cache: ApolloCache<any>) => {
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'favorites',
        });
        cache.writeFragment({
          id: `${task.__typename}:${task.id}`,
          fragment: gql`
                fragment MyTaskDefinition on TaskDefinition {
                    isFavorite
                }
            `,
          data: {
            isFavorite: !task.isFavorite,
          },
        });
      };

      if (task.isFavorite) {
        await removeFavorite({
          variables: {
            dto: {
              taskId: task.id,
            },
          },
          update,
        });
      } else {
        await addFavorite({
          variables: {
            dto: {
              taskId: task.id,
            },
          },
          update,
        });
      }
    },
    [task, addFavorite, removeFavorite],
  );

  return (
    <Tile
      link={`/task/${task.id}`}
      title={task.taskDefinitionLabel ?? 'Chat'}
      description={task.summary ?? ''}
      illustration={'begin-chat'}
    >
      <div className="absolute top-[15px] right-[15px] space-x-5">
        {addFavoriteLoading || removeFavoriteLoading ? (
          <div className="cursor-pointer p-2">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className="cursor-pointer p-2" onClick={handleToggleFavorite}>
            <FontAwesomeIcon icon={task.isFavorite ? faStarFilled : faStar} />
          </div>
        )}
      </div>
    </Tile>
  );
};

export default TaskTile;
