import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Route, Routes } from 'react-router-dom';
import FullPageLoading from './components/base/FullPageLoading';
import Loading from './components/base/Loading';
import AdminLayout from './components/layout/AdminLayout';
import CompanyAdminLayout from './components/layout/CompanyAdminLayout';
import Layout from './components/layout/Layout';
import LoginLayout from './components/layout/LoginLayout';
import useCompany from './context/company/useCompany';
import ChangePassword from './pages/ChangePassword';
import CreateGenericTask from './pages/CreateGenericTask';
import CreateTask from './pages/CreateTask';
import Error404 from './pages/Error404';
import Favorites from './pages/Favorites';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Overview from './pages/Overview';
import Task from './pages/Task';
import TaskDefinitionSearch from './pages/TaskDefinitionSearch';
import TaskDefinitionSettings from './pages/TaskDefinitionSettings';
import TaskOverview from './pages/TaskOverview';
import AdminDashboard from './pages/admin/AdminDashboard';
import Categories from './pages/admin/Categories';
import Companies from './pages/admin/Companies';
import GatewayConfigs from './pages/admin/GatewayConfigs';
import InstanceConfig from './pages/admin/InstanceConfig';
import Modules from './pages/admin/Modules';
import TaskDefinition from './pages/admin/TaskDefinition';
import TaskDefinitions from './pages/admin/TaskDefinitions';
import Company from './pages/companyAdmin/Company';
import CompanyAdminDashboard from './pages/companyAdmin/CompanyAdminDashboard';
import KnowledgeBase from './pages/companyAdmin/KnowledgeBase';
import KnowledgeBases from './pages/companyAdmin/KnowledgeBases';
import Users from './pages/companyAdmin/Users';

const App = () => {
  const {
    isLoading: authIsLoading,
    isAuthenticated,
    activeNavigator,
    error: authError,
  } = useAuth();
  const { isLoading } = useCompany();

  if (authIsLoading || isLoading) return <FullPageLoading />;

  switch (activeNavigator) {
    case 'signinSilent':
      return <FullPageLoading message="Anmelden..." />;
    case 'signoutRedirect':
      return <FullPageLoading message="Abmelden..." />;
  }

  if (authError) return <FullPageLoading message="Abmelden..." />;

  if (!isAuthenticated)
    return (
      <Routes>
        <Route element={<LoginLayout />}>
          <Route element={<Logout />} path="/logout" />
          <Route element={<Login />} path="*" />
        </Route>
      </Routes>
    );

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<Overview />} path="" />
        <Route
          element={<TaskOverview />}
          path="module/:moduleId/:categoryId?"
        />
        <Route
          element={<TaskDefinitionSettings />}
          path="taskDefinitionSettings/:taskDefinitionId"
        />
        <Route element={<ChangePassword />} path="changePassword" />
        <Route element={<Task />} path="tasks" />
        <Route element={<Task />} path="task/:taskId" />
        <Route element={<TaskDefinitionSearch />} path="search" />
        <Route element={<CreateTask />} path="createTask/:taskDefinitionId" />
        <Route element={<CreateGenericTask />} path="createTask" />
        <Route element={<Favorites />} path="favorites" />
        <Route path="companyAdmin" element={<CompanyAdminLayout />}>
          <Route element={<CompanyAdminDashboard />} path="" />
          <Route element={<KnowledgeBases />} path="knowledgeBases" />
          <Route element={<KnowledgeBase />} path="knowledgeBase/:id" />
          <Route element={<Users />} path="users" />
          <Route element={<Company />} path="company" />
        </Route>
        <Route path="admin" element={<AdminLayout />}>
          <Route element={<AdminDashboard />} path="" />
          <Route element={<InstanceConfig />} path="instanceConfig" />
          <Route element={<GatewayConfigs />} path="providerConfigs" />
          <Route element={<Categories />} path="categories" />
          <Route element={<Companies />} path="companies" />
          <Route element={<Modules />} path="modules" />
          <Route element={<TaskDefinitions />} path="taskDefinitions" />
          <Route element={<TaskDefinition />} path="taskDefinition/:id" />
          <Route element={<Error404 />} path="*" />
        </Route>
        <Route element={<Error404 />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
