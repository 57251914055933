import { faList, fal as icons } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Module } from '../../graphql/schema';

interface Props {
  module: Module;
  activeCategoryId?: string;
}

const CategoriesSidebar = ({ module, activeCategoryId }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-2">
      <div
        onClick={() => {
          navigate(`/module/${module.id}`);
        }}
        className={`theme-bg-primary-hover ${!activeCategoryId ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
      >
        <FontAwesomeIcon icon={faList} />
        <span>Alle</span>
      </div>

      {module.categories?.map((category) => (
        <div
          key={category.id}
          onClick={() => {
            navigate(`/module/${module.id}/${category.id}`);
          }}
          className={`theme-bg-primary-hover ${activeCategoryId === category.id ? 'theme-bg-primary' : ''} cursor-pointer rounded-xl p-[10px] px-[15px] flex space-x-2 items-center`}
        >
          <FontAwesomeIcon icon={icons[category.icon]} />
          <span>{category.title}</span>
        </div>
      ))}
    </div>
  );
};

export default CategoriesSidebar;
